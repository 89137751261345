<script>
    export let preselected = false;
    export let selector;

    function selectedSwitch(event) {
        const element = event.target;
        if (element.classList.contains("selected")) {
            element.classList.remove("selected");
        } else {
            element.classList.add("selected");
        }
    }
</script>

<style>
    .select-icon {
        
        --fa-check-icon: "\f00c";
        --icon-bg-color: var(--stratoOrange, #f80);
        --icon-color: black;
        --icon-selected-color: white;

        z-index: 1;
        line-height: 24px;
        width: 24px;
        margin: 18px 15px;
        opacity: .15;
        text-align: center;
        cursor: pointer;
    }
    .select-icon.selected {
        opacity: 1;
    }
    .select-icon::before {
        content: "\f00c";
        color: var(--icon-color);
    }
    .selected::before {
        content: "\f00c";
        color: var(--icon-selected-color);
        background-color: var(--icon-bg-color);
        border-radius: 2px;
        padding: 2px;
    }
</style>

<div class="select-icon fa {preselected ? "selected" : ""}" id="selecticon-{selector}" name=select-{selector} alt=select-{selector} data-selector="{selector}"
    on:click={(e) => {selectedSwitch(e)}}
    on:keypress={(e) => {
        if (e.key == "Enter") {
            selectedSwitch(e);
        }
    }}
></div>