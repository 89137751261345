<script>
    import * as entryFunctions from "../javascript/entry";

    let shareData = {};
    const CLIENT_ID = "e362a5f8e4d3e7c98c0d0d43cbe04cd1";
</script>

<style>
    button {
        background-color: #0077BB;
        border: none;
        color: white;
        padding: 5px;
        padding-inline: 30px;
        font-size: 16px;
        margin: 5px;
    }
    button:hover {
        cursor: pointer;
    }
    .display {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        font-family: "Open sans", Arial, Helvetica, sans-serif;
        font-size: 16px;
    }

</style>

<div class="display">
    <div class="buttons">
        <button on:click={async () => {
                shareData = await entryFunctions.getHiDriveShareLink(CLIENT_ID);
            }}>Link einfügen</button>
        <button on:click={async () => {
                shareData = await entryFunctions.getHiDriveShareUrl(CLIENT_ID);
            }}>URL einfügen</button>
        <button on:click={async () => {
                shareData = await entryFunctions.getHiDriveThumbnail(CLIENT_ID);
            }}>Thumbnail einfügen</button>
    </div>
    <button on:click={() => {console.log(shareData)}}>Show result</button>
</div>
