import { HiDriveApiHandler } from "./api_handler";
import { accessToken, currentDirectory, dirData, fileInfo, homeDir, options, userFeatures } from "./stores";
import type { DirectoryData, FileData, UserFeatures } from "./custom_types";
import Utils from "./utils";
import { get } from "svelte/store";

let apiHandler: HiDriveApiHandler;
accessToken.subscribe(token => {
    apiHandler = new HiDriveApiHandler(token);
});

let currentDir: string;
currentDirectory.subscribe(dir => {
    currentDir = dir;
})

export default {
    async setUserInfo() {
        try {
            const userData = await apiHandler.getUserInfo();
            const username = userData.alias;
            const path = `/users/${username}`;
            homeDir.set(path);
            currentDirectory.set(path);

            const features = await apiHandler.getUserFeatures();
            userFeatures.set(<UserFeatures>features);

        } catch (error) {
            console.error(error);
        }
    },

    async getDirData() {
        if (!currentDir) {
            await this.setUserInfo();
        }
        try {
            const data = <DirectoryData>(await apiHandler.getDir(currentDir));
            if (!data.members) {
                console.error(`Error getting data for ${currentDir}`);
                return;
            }
            
            const dContentData = Utils.getDirContentData(data.members);
            const dData = {...dContentData, name: data.name};
            const directoryData = {data: dData, members: data.members};
            dirData.set(directoryData);
        } catch (error) {
            console.error(error);
        }
    },

    async getShareInfo(): Promise<FileData | void> {
        const fInfo = get(fileInfo);
        try {
            if (fInfo.rshare) {
                if (fInfo.type == "dir") {
                    const res = await apiHandler.getShare(fInfo.rshare[0].id);
                    return <FileData>res;
                } else {
                    const res = await apiHandler.getShareLink(fInfo.rshare[0].id);
                    return <FileData>res;
                }
            } else {
                console.error("Can't get share info from non share file.");
                return;
            }
        } catch (error) {
            console.error(error);
            return;
        }
    },

    async getShareLink(): Promise<void|string> {
        let link;
        const opt: Record<string, Record<string, string>> = get(options);
        const originalOptions = opt.original;
        const newOptions = opt.new;
        const optionsSet = !(Utils.objectEquals(originalOptions, newOptions));
        const fInfo: FileData = get(fileInfo);
        if (fInfo.type == "dir") {
            try {
                if (fInfo.rshare && optionsSet) {
                    link = this.updateShare("dir", fInfo.rshare[0].id, newOptions);
                } else if (fInfo.rshare) {
                    const res = await apiHandler.getShare(fInfo.rshare[0].id);
                    link = res.uri;
                } else {
                    const res = await apiHandler.createShare(fInfo.path, newOptions);
                    link = res.uri;
                }
            } catch (error) {
                console.error(error);
                return;
            }
        } else {
            try {
                if (fInfo.rshare && optionsSet) {
                    link = this.updateShare("file", fInfo.rshare[0].id, newOptions);
                } else if (fInfo.rshare) {
                    const res = await apiHandler.getShareLink(fInfo.rshare[0].id);
                    link = res.uri;
                } else {
                    const res = await apiHandler.createShareLink(fInfo.path, "file", newOptions);
                    link = res.uri;
                }
            } catch (error) {
                console.error(error);
                return;
            }
        }
        return link;
    },

    async updateShare(type: string, id: string, opt: Record<string, string>): Promise<string|void> {
        let newFInfo: FileData;
        if (type == "dir") {
            newFInfo = await apiHandler.putShare(id, opt);
        } else {
            newFInfo = await apiHandler.putShareLink(id, opt);
        }
        return newFInfo.uri;
    },

    getThumbnailSource(): string | void{
        const fInfo: FileData = get(fileInfo);
        try {
            const url = apiHandler.getThumbnailUrl(fInfo.path);
            return url.toString();
        } catch (error) {
            console.error(error);
        }
    }
}
