import Authorization from "./authorization";
import { shareLink, displayChoice, fileInfo } from "./stores";
import { get } from "svelte/store";
import type { FileData } from "./custom_types";
import ApiUtils from "./api_utils";

const authorization = new Authorization();

export function getToken() {
    try {
        authorization.getAccessToken();
    } catch (error) {
        console.error(error);
    }
}

export function getDisplayChoice() {
    const cookie = document.cookie;
    const dc = cookie.match(/displayChoice=([a-zA-Z]+)/);
    if (dc) {
        displayChoice.set(dc[1]);
    } else {
        alert("Error reading display choice");
    }
}

export async function finish() {    
    const dc = get(displayChoice);

    let thumbnailSrc;
    if(dc == "thumbnail") {
        thumbnailSrc = ApiUtils.getThumbnailSource();
        // invalid file type for thumbnail
        if (!thumbnailSrc) return;
    }

    const sl = await ApiUtils.getShareLink();
    if (!sl) return;
    shareLink.set(<string>sl);

    const fInfo: FileData = get(fileInfo);
    const payload = {
        shareLink: sl,
        fileInfo: fInfo,
        displayChoice: dc,
        thumbnailSource: thumbnailSrc
    }
    window.opener.postMessage(payload);
    window.close();
}