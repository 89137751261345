import type { FileData } from "./custom_types";


export default {

    checkUrl(url:string): boolean {
        const urlRegex = /^(http)s?:\/\/[^ "]+$/;
        return urlRegex.test(url);
    },

    /**
     * Creates a centered popup window with given url and size
     * @param {string} url
     * @param {number} w Popup Width
     * @param {number} h Popup Height
     */
    createPopup(url: string, w: number, h: number): Window | null {
        if (!this.checkUrl((url))) {
            console.error(`${url} is not a valid URL`);
            return null;
        }
        const leftPosition = (screen.width) ? (screen.width - w) / 2 : 0;
        const topPosition = (screen.height) ? (screen.height - h) / 2 : 0;
        const settings = 
            "height=" + h +
            ",width=" + w +
            ",top=" + topPosition +
            ",left=" + leftPosition;
        try {
            return window.open(url, "popup", settings);
        } catch (error) {
            console.error(error);
            return null;
        }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objectEquals(obj1: Record<any, any>, obj2: Record<any, any>) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if(keys1.length !== keys2.length) return false;

        for (const key of keys1) {
            if (obj1[key] !== obj2[key]) return false;
        }
        return true;
    },

    convertTimestamp(ts: number): string {
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "numeric",
            year: "numeric"
        };
        const date = new Date(ts * 1000).toLocaleDateString("de-DE", options);
        return date;
    },

    sizeToString(byte: number): string {
        const kilo = 10 ** 3;
        const mega = 10 ** 6;
        const giga = 10 ** 9
        if (byte >= giga) {
            return `${Math.floor(byte/giga)} GB`;
        } else if (byte >= mega) {
            return `${Math.floor(byte/mega)} MB`;
        } else if (byte >= kilo) {
            return `${Math.floor(byte/kilo)} KB`;
        } else {
            return `${byte} B`
        }
    },

    isImage(filename: string): boolean {
        const imageFormats = ["jpg", "png", "gif", "apng", "avif", "webp"];
        const chunks = filename.split(".");
        return (imageFormats.includes(chunks[chunks.length - 1]))
    },

    getDirContentData(members: FileData[]): {dirs: number, files:number} {
        const len = members.length;
        const dirCnt = members.reduce((cnt: number, curr: FileData) => {
            if (curr.type == "dir") {
                return cnt + 1;
            }
            return cnt;
        }, 0)
        const fileCnt = len - dirCnt;

        return {dirs: dirCnt, files: fileCnt}
    }
}
