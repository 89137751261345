import { currentDirectory, fileInfo, status } from "./stores";
import { FileData } from "./custom_types";
import ApiUtils from "./api_utils";

let currentDir: string;
currentDirectory.subscribe(dir => {
    currentDir = dir;
})

export default {
    setFileInfo(entry: FileData) {
        fileInfo.set(entry);
        status.set("choosingOptions");
    },

    navLevelUp() {
        if (currentDir == "/") return;
        const lastIndex = currentDir.lastIndexOf("/");
        const newDir = currentDir.slice(0, lastIndex);
        currentDirectory.set(newDir);
        ApiUtils.getDirData();
    }
}