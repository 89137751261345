import Authorization from "./authorization";
import type { FileInfo } from "./custom_types";

const authorization = new Authorization();

export async function getHiDriveShareUrl(id: string): Promise<{"url": string}> {
    return <Promise<{"url": string}>>authorization.go("url", id);
}


type hdsl = Promise<{fileInfo: FileInfo, shareLink: string}>
export async function getHiDriveShareLink(id: string): hdsl {
    return <hdsl>authorization.go("link", id);
}

type hdtn = Promise<{
    fileInfo: FileInfo,
    thumbnailSource: string,
    shareLink: string
}>
export async function getHiDriveThumbnail(id: string): hdtn {
    return <hdtn>authorization.go("thumbnail", id);
}