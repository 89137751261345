<style>
    list-header {
        --borderStyle: var(--tableRowBorder, 1px dotted #bbb);
        --headerFontColor: var(--tableHeadFontColor, #999);

        display: block;
        height: 60px;
        float: none;
        box-sizing: border-box;
        margin: 0;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        width: auto;
        border-bottom: var(--borderStyle);
        color: var(--headerFontColor);
    }
    .itemcontent {
        display: flex;
        flex-direction: row;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
    }
    .select-icon {
        left: 0;
        line-height: 24px;
        margin: 18px 15px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 24px;
        z-index: 1;
    }
    .file-item-icon {
        display: block;
        height: 40px;
        margin: 10px 10px 0 59px;
        width: 40px;
    }
    .file-item-label {
        display: block;
        line-height: 60px;
        overflow: hidden;
        padding: 0 10px;
        text-overflow: ellipsis;
    }
    .file-item-displayname {
        flex: 1;
    }
    .file-item-share-icon{
        min-width: 12px;
        padding: 22px 5px;
    }
    .file-item-category {
        width: 50px;
    }
    .file-item-size {
        width: 75px;
    }
    .file-item-date {
        width: 125px;
    }
</style>

<list-header>
    <div class="select-icon"></div>
    <div class="itemcontent">
        <span class="file-item-icon"></span>
        <span class="file-item-label file-item-displayname">
            <span class="label-text">Name</span>
        </span>
        <span class="file-item-share-icon"></span>
        <span class="file-item-label file-item-category">
            <span class="label-text">Typ</span>
        </span>
        <span class="file-item-label file-item-size">
            <span class="label-text">Größe</span>
        </span>
        <span class="file-item-label file-item-date">
            <span class="label-text">Geändert</span>
        </span>
    </div>
</list-header>