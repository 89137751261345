<style>
    .hidrive-header {
        --bgColor: var(--stratoOrange, #f80);

        background-color: var(--bgColor);
        position: relative;
        padding: 12px;
        margin-bottom: 15px;
    }
    .hidrive-logo {
        height: 15px;
    }
</style>
<div class="hidrive-header">
    <img src="src/img/logo.svg" alt="logo" class="hidrive-logo">
</div>