import {status, accessToken, shareLink, thumbnailSource, fileInfo, homeURL} from "./stores";
import { get } from "svelte/store"
import Utils from "./utils";
import type { FileInfo } from "./custom_types";

type GoReturnTypes = 
    {"url": string} |
    {"fileInfo": FileInfo, "shareLink": string} |
    {"fileInfo": FileInfo, "thumbnailSource": string, "shareLink": string}


export default class Authorization {
    POPUP_WIDTH = 700;
    POPUP_HEIGHT = 500;

    async go(display: string, id: string): Promise<GoReturnTypes> {
        const HIDRIVE_URL = `https://fe-hdf.stg.rzone.de/client/authorize?client_id=${id}&response_type=token&scope=admin,rw`;
        homeURL.set(window.location.origin);
        return new Promise((resolve) => {
            window.addEventListener("message", (e) => {
                const HOME_URL = get(homeURL); 
                if(e.origin !== HOME_URL) {
                    console.error(e.origin + " does not match " + HOME_URL)
                    return;
                }
                const data = e.data;
                fileInfo.set(data.fileInfo);
                thumbnailSource.set(data.thumbnailSource);
                shareLink.set(data.shareLink);
                status.set("done");
                resolve(this.formatResolveData(data, display))
            })
            document.cookie = `displayChoice=${display}`;
            Utils.createPopup(HIDRIVE_URL, this.POPUP_WIDTH, this.POPUP_HEIGHT);
        })
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private formatResolveData(data: any, display: string): GoReturnTypes {
        let resolveData: GoReturnTypes;
        if (display == "thumbnail") {
            resolveData = {
                "fileInfo": {
                    "name": data.fileInfo.name,
                    "path": data.fileInfo.path,
                    "type": data.fileInfo.type
                },
                "thumbnailSource": data.thumbnailSource,
                "shareLink": data.shareLink
            };
            return resolveData;
        } else if (display == "link") {
            resolveData = {
                "fileInfo": {
                    "name": data.fileInfo.name,
                    "path": data.fileInfo.path,
                    "type": data.fileInfo.type
                },
                "shareLink": data.shareLink
            }
            return resolveData;
        } else {
            resolveData = {
                "url":data.shareLink
            }
            return resolveData;
        } 
    }

    getAccessToken(): void {
        const accessTokenRegex = /(?<=#access_token=)[^&]+/;
        if(!window.opener) {
            return;
        }
        const hash = window.location.hash;
        const match = hash.match(accessTokenRegex);
        if (!match) throw Error("Error getting Access Token");
        const token = match[0];
        accessToken.set(token);
        status.set("authorized");
    }
}
