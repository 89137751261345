<script>
    import { homeDir, currentDirectory, dirData } from "../../javascript/stores";
    import FileTableFunctions from "../../javascript/fileTable";

</script>

<style>
    dir-info {
        --backnavColor: var(--levelUpColor, #07b);
        --disabledNavColor: var(--disabledColor, #ccc);
        
        margin-left: 20px;
    }
    dir-info i {
        font-size: 22px;
        margin-right: 5px;
        color: var(--backnavColor);
        cursor: pointer;
    }
    dir-info .disabled {
        color: var(--disabledNavColor);
        cursor: default;
    }
    dir-info span {
        font-size: 18px;
    }
    dir-info .disp-foldername {
        font-weight: bold;
    }
</style>

<dir-info>
    {#if $homeDir == $currentDirectory}
        <i alt="level-up" class="fa fa-level-up fa-flip-horizontal disabled"
        />
    {:else}
        <i alt="level-up" class="fa fa-level-up fa-flip-horizontal"
        on:click={FileTableFunctions.navLevelUp}
        on:keypress={e => {
            if (e.key == "Enter") {
                FileTableFunctions.navLevelUp();
            }
        }}/>
    {/if}
    <span class="disp-foldername">
        {$dirData.data.name}
    </span>
    <span class="disp-folderdata">
        / {$dirData.data.dirs} Ordner, {$dirData.data.files} Dateien
    </span>
</dir-info>