type Header = Record<string, string>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponseObject = Record<string, any>;

import { FileData } from "./custom_types";
import { getRequest, postRequest, putRequest } from "./requests";

export class HiDriveApiHandler {
    private apiPath = "https://fe-hdf.stg.rzone.de/api/";
    private authHeader: Header;
    private accessToken: string;
    private fileInfoFields = "id,path,pid,status,type,uri,has_password,maxcount,name,remaining,size,ttl";

    constructor(accessToken: string) {
        this.accessToken = accessToken;
        this.authHeader = {
            "Authorization": `Bearer ${accessToken}`
        }
    }

    async getUserInfo(): Promise<ResponseObject> {
        const url = new URL(`${this.apiPath}user/me`);
        return getRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error getting User Info: ${err.message}`);
        });
    }

    async getUserFeatures(): Promise<ResponseObject> {
        const fields = [
            "sharelink_enabled",
            "sharelink_password",
            "sharelink_ttl",
            "share_enabled",
            "share_password",
            "share_ttl"            
        ];
        const params = new URLSearchParams({"fields": fields.toString()})
        const url = new URL(`${this.apiPath}features?${params}`);
        return getRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error getting User Features: ${err.message}`);
        });
    }

    /**
     * @async
     * @param path path to directory, e.g. /users/user/foo
     * @returns Promise containing directory and subdirectory info, or void on failure
     */
    async getDir(path: string): Promise<ResponseObject | void> {
        const fields = [
            "name",
            "path",
            "type",
            "category",
            "has_dirs",
            "shareable",
            "rshare",
            "members.path",
            "members.type",
            "members.has_dirs",
            "members.shareable",
            "members.size",
            "members.ctime",
            "members.rshare"
        ];
        const params = new URLSearchParams({
            "path": path,
            "fields": fields.toString()
        });
        const destUrl = `${this.apiPath}dir`;
        const url = new URL(`${destUrl}?${params}`);

        return getRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error getting directory: ${err.message}`);
        });
    }

    /**
     * @async
     * @param path Path to new directory
     * @example /users/foo/bar creates folder bar in user foo's root directory.
     * @returns Information about created directory, or void on failure
     */
    async createDir(path: string): Promise<ResponseObject | void> {
        const params = new URLSearchParams({
            "path": path,
            "on_exist": "autoname"
        });
        const destUrl = `${this.apiPath}dir`
        const url = new URL(`${destUrl}?${params}`);

        return postRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error creating directory: ${err.message}`);
        });
    }

    /**
     * @async
     * @param src Path of source
     * @param dst Path of destination
     * @example copyDir("/users/user/foo", "/users/user/bar/foo") copies directory foo into bar
     * @returns Information about with copy created directory, or void on failure
     */
    async copyDir(src: string, dst: string): Promise<ResponseObject | void> {
        const params = new URLSearchParams({
            "src": src,
            "dst": dst,
            "on_exist": "autoname"
        });
        const destUrl = `${this.apiPath}dir/copy`
        const url = new URL(`${destUrl}?${params}`);

        return postRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error copying directory: ${err.message}`);
        });
    }

    /**
     * @async
     * @param path Path of folder to share 
     * @returns share uri, or void on failure
     */
    async createShare(path: string, options?: Record<string, string>): Promise<ResponseObject> {
        const params = new URLSearchParams({
            "path": path
        });
        if (options?.password) params.append("password", options.password);
        if (options?.maxcount) params.append("maxcount", `${options.maxcount}`);
        if (options?.ttl) params.append("ttl", `${options.ttl}`);

        const destUrl = `${this.apiPath}share`;
        const url = new URL(`${destUrl}?${params}`);

        return postRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error creating share: ${err.message}`)
        })
        .then(res => {
            if (res.status == "valid") {
                return res;
            } else {
                throw new Error("Failed creating the share: " + res.status);
            }
        });
    }

    /**
     * @async
     * @param path path to file to share
     * @param type type of file to share (e.g.: file)
     * @returns sharelink, or void on failure
     */
    async createShareLink(path:string, type: string, options?: Record<string, string>): Promise<ResponseObject> {
        const params = new URLSearchParams({
            "path": path,
            "type": type
        });
        if (options?.password) params.append("password", options.password);
        if (options?.maxcount) params.append("maxcount", `${options.maxcount}`);
        if (options?.ttl) params.append("ttl", `${options.ttl}`);

        const destUrl = `${this.apiPath}sharelink` 
        const url = new URL(`${destUrl}?${params}`);

        return postRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error creating sharelink: ${err.message}`);
        })
        .then(res => {
            if (res.status == "valid") {
                return res;
            } else {
                throw new Error("Failed to create the sharelink: " + res.status);
            }
        });
    }

    /**
     * @async
     * @param id sharelink id
     * @returns sharelink, or void on failure
     */
    async getShareLink(id: string): Promise<ResponseObject> {
        const params = new URLSearchParams({
            "id": id,
            "fields": this.fileInfoFields
        });
        const destUrl = `${this.apiPath}sharelink` 
        const url = new URL(`${destUrl}?${params}`);

        return getRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error getting sharelink: ${err.message}`);
        })
        .then(res => {
            if (res.status == "valid") {
                return res;
            } else {
                throw new Error("Failed to get the sharelink: " + res.status);
            }
        });
    }

    /**
     * @async
     * @param id sharelink id
     * @returns uri to share, or void on failure
     */
    async getShare(id: string): Promise<ResponseObject> {
        const params = new URLSearchParams({
            "id": id,
            "fields": this.fileInfoFields
        });
        const destUrl = `${this.apiPath}share` 
        const url = new URL(`${destUrl}?${params}`);

        return getRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error getting share: ${err.message}`);
        })
        .then(res => {
            if (res.status == "valid") {
                return res;
            } else {
                throw new Error("Failed to get the share: " + res.status);
            }
        });
    }

    async putShare(id: string, options:Record<string, string>): Promise<FileData> {
        const params = new URLSearchParams({"id": id, ...options});
        const destUrl = `${this.apiPath}share`;
        const url = new URL(`${destUrl}?${params}`);

        return putRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error getting share: ${err.message}`);
        })
        .then(res => {
            if (res.status == "valid") {
                return <FileData>res;
            } else {
                throw new Error("Failed to get the share: " + res.status);
            }
        });
    }

    async putShareLink(id: string, options:Record<string, string>): Promise<FileData> {
        const params = new URLSearchParams({"id": id, ...options});
        const destUrl = `${this.apiPath}sharelink`;
        const url = new URL(`${destUrl}?${params}`);

        return putRequest(url.toString(), this.authHeader)
        .catch(err => {
            throw new Error(`Error getting share: ${err.message}`);
        })
        .then(res => {
            if (res.status == "valid") {
                return <FileData>res;
            } else {
                throw new Error("Failed to get the share: " + res.status);
            }
        });
    }

    /**
     * 
     * @param path path to file
     * @param width width of requested thumbnail
     * @param height height of requested thumbnail
     * @returns url to image
     */
    getThumbnailUrl(path: string): URL {
        const params  = new URLSearchParams({
            "path": path,
            "access_token": this.accessToken
        });

        const destUrl = `${this.apiPath}file/thumbnail`;
        const url = new URL(`${destUrl}?${params}`);

        return url;
    }
}
