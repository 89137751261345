type Header = Record<string, string>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponseObject = Record<string, any>;

export async function getRequest(url: string, headers: Header): Promise<ResponseObject> {
    return fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers
    })
    .catch(error => { 
        throw new Error(`Error in Get Request: ${error}`);
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`Received status ${response.status}: ${response.statusText}`);
        }
        return response.json();
    })
    .then(data => {
        return data;
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function postRequest(url: string, headers: Header, contentType?: string, body?: any): Promise<ResponseObject> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: Record<string, any> = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers
    }
    if (contentType && body) {
        payload["Content-Type"] = contentType;
        payload["body"] = body;
    }
    return fetch(url, payload)
    .catch(error => { 
        throw new Error(`Error in Post Request: ${error}`);
    })
    .then(response => {
        if (!response.ok) {
            throw Error(`Received status ${response.status}: ${response.statusText}`);
        }
        return response.json();
    })
    .then(data => {
        return data;
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function putRequest(url: string, headers: Header, contentType?: string, body?: any): Promise<ResponseObject> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: Record<string, any> = {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        headers
    }
    if (contentType && body) {
        payload["Content-Type"] = contentType;
        payload["body"] = body;
    }
    return fetch(url, payload)
    .catch(error => { 
        throw new Error(`Error in Put Request: ${error}`);
    })
    .then(response => {
        if (!response.ok) {
            throw Error(`Received status ${response.status}: ${response.statusText}`);
        }
        return response.json();
    })
    .then(data => {
        return data;
    });
}