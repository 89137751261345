<script>
    import { finish } from "../javascript/main";
    import SelectIcon from "./partials/SelectIcon.svelte";
    import { options, fileInfo, status, userFeatures } from "../javascript/stores";
    import { get } from "svelte/store";
    import { onMount } from "svelte";

    let optionSelection;

    function valid_ttl(ttl) {
        const features = get(userFeatures);
        const fInfo = get(fileInfo);
        if (!ttl) return true;
        if (fInfo.type == "dir") {
            // share_ttl is null for unlimited
            if (!features.share_ttl) return true;
            if (ttl < features.share_ttl && ttl >= 0) return true;
        } else {
            // share_ttl is null for unlimited
            if (!features.sharelink_ttl) return true;
            if (ttl < features.sharelink_ttl && ttl >= 0) return true;
        }
        return false;
    }

    function getOptions() {
        const selectedElements = optionSelection.querySelectorAll(".select-icon.selected");
        let selectData = {
            "password": "",
            "maxcount": "",
            "ttl": ""
        };
        for (const element of selectedElements) {
            const selector = element.dataset.selector;
            const input = optionSelection.querySelector(`.input-${selector}`)
            const val = input.value;
            if (!val) {
                alert(`Felder für gewählte Optionen dürfen nicht leer sein.`);
                return;
            }
            selectData[selector] = input.value;
        }
        if ( selectData["ttl"] && !valid_ttl(selectData["ttl"])) {
            alert("Einstellung für TTL ist entweder zu hoch für ihren Tarif oder ihr Tarif erlaubt keine zeitliche Begrenzung von Shares");
        }
        return selectData;
    }

    function updateOptions() {
        let info = get(fileInfo);
        if (info.rshare) {
            let opt = get(options);
            const rshare = info.rshare[0]
            if (rshare.ttl && rshare.ttl <= 0) {
                delete(info[rshare])
                return;
            }
            let originalOptions = {};
            originalOptions.password = rshare.password ? rshare.password : "";
            originalOptions.maxcount = rshare.maxcount ? `${rshare.maxcount}` : "";
            originalOptions.ttl = rshare.ttl ? `${rshare.ttl}` : "";
            opt.original = originalOptions;
            options.set(opt);
        }
    }

    onMount(() => {
        updateOptions();
    })
    
    function proceed() {
        const opt = get(options);
        const newOptions = getOptions();
        opt.new = newOptions;
        options.set(opt);
        finish();
    }

    function cancel() {
        let opt = get(options);
        opt.new = {
            "password": "",
            "maxcount": "",
            "ttl": ""
        };
        options.set(opt);
        status.set("authorized");
    }
</script>

<style>
    options-selection {
        display: block;
        border-top: 1px dotted grey;
        font-family: "Open sans", Arial, Helvetica, sans-serif;
        font-size: 16px;
    }
    share-option {
        display: flex;
        height: 60px;
        float: none;
        box-sizing: border-box;
        margin: 0;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        width: auto;
        border-bottom: 1px dotted grey;
    }
    .option-label {
        width: fit-content;
        line-height: 60px;
        font-family: "Open sans", Arial, Helvetica, sans-serif;
        margin-left: 10px;
    }
    share-option input {
        height: 30px;
        margin: 15px 10px 15px auto;
        text-overflow: clip;
        border-radius: 8px;
        border: 1px solid black;
        padding: 0 10px;
        font-size: 16px;
    }
    button {
        background-color: #0077BB;
        border: none;
        color: white;
        padding: 5px;
        padding-inline: 30px;
        font-size: 16px;
        cursor: pointer;
    }
    .buttons {
        margin: 5px;
    }
</style>




<options-selection bind:this={optionSelection}>
    {#if $fileInfo.type == "dir" && $userFeatures.share_password ||
        $fileInfo.type == "file" && $userFeatures.sharelink_password
    }
        <share-option>
            <SelectIcon preselected={!!$options.original.password} selector="password"/>
            <span class="option-label">Passwort für den Share Zugriff festlegen</span>
            <input type="password" class="input-password" name="input-password" placeholder="Passwort"
            value={$options.original.password}>
        </share-option>
    {/if}
    <share-option>
        <SelectIcon preselected={!!$options.original.ttl} selector="ttl"/>
        <span class="option-label">Zeit bis zum Ablauf des Share festlegen (in Sekunden)</span>
        <input type="text" class="input-ttl" name="input-ttl" placeholder="000000"
        value={$options.original.ttl}>
    </share-option>
    <share-option>
        <SelectIcon preselected={!!$options.original.maxcount} selector="maxcount"/>
        <span class="option-label">Maximale Anzahl an Zugriffen festlegen</span>
        <input type="text" class="input-maxcount" name="input-maxcount" placeholder="0"
        value={$options.original.maxcount}>
    </share-option>
    <div class="buttons">
        <button on:click={proceed}>OK</button>
        <button on:click={cancel}>Abbrechen</button>
    </div>
</options-selection>