<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>

<script>
    import FileTable from "./FileTable.svelte";
    import OptionSelection from "./OptionSelection.svelte";
    import HiDriveHeader from "./partials/HiDriveHeader.svelte";
    import { status } from "../javascript/stores.js";
    import { getToken, getDisplayChoice } from "../javascript/main";
    import ApiUtils from "../javascript/api_utils";

    function removeBodyMargin() {
        const fp = document.querySelector(".filepicker");
        const body = fp.closest("body");
        body.style.margin = "0";
    }

    getDisplayChoice();
</script>

<style>
    :root {
        --disabledColor: #ccc;
        --levelUpColor: #07b;
        --stratoOrange: #f80;
        --iconDefaultColor: #999;
        --tableHeadFontColor: #999;
        --tableRowBorder: 1px dotted #bbb;
        --backgroundHoverColor: #eee;
    }
    .filepicker {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        font-family: "Open sans", Arial, Helvetica, sans-serif;
        font-size: 16px;
    }
</style>

<svelte:window 
    on:load={
        () => {
            getToken();
            removeBodyMargin();
        }}
/>

<HiDriveHeader/>

<div class="filepicker">
    {#if $status == "authorized"}
        {#await ApiUtils.getDirData()}
            <p>loading ...</p>
        {:then}
            <FileTable/>
        {:catch error}
            <p style="color: red">{error.message}</p>
        {/await} 
        <!--TODO: change Status-->
    {:else if $status == "choosingOptions"}
        <OptionSelection/>
    {/if}
</div>