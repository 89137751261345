<script>
    import ListItem from "./ListItem.svelte";
    import ListHeader from "./ListHeader.svelte";
    import DirInfo from "./DirInfo.svelte";
    import { dirData, currentDirectory, displayChoice, userFeatures } from "../../javascript/stores";
    import FileTableFunctions from "../../javascript/fileTable";
    import api_utils from "../../javascript/api_utils";
    import utils from "../../javascript/utils";
    import { get } from "svelte/store";

    function handleListItemClick(e) {
        const eTarget = e.detail.event.target;
        const itemdata = e.detail.itemdata;
        if (eTarget.closest(".itemcontent")) {
            if (itemdata.type == "dir") {
                currentDirectory.set(itemdata.path);
                api_utils.getDirData();
            }
        } else if (eTarget.closest(".select-icon")) {
            FileTableFunctions.setFileInfo(itemdata);
        }
    }

    function isDisabled(itemdata) {
        const filename = itemdata.name;
        const fileType = itemdata.type;
        const isImage = utils.isImage(filename);
        const dispChoice = get(displayChoice);
        const features = get(userFeatures);
        if ((dispChoice == "thumbnail" && !isImage) ||
            (fileType == "dir" && !features.share_enabled) ||
            (fileType == "file" && !features.sharelink_enabled)) return true;
        return false;
    }
</script>

<style>
    list-layout {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        flex-direction: column;
    }
    list-content {
        display: block;
        flex: 1 0 auto;
        box-sizing: border-box;
    }
</style>

<list-layout>
    <DirInfo/>
    <ListHeader/>
    <list-content>
        {#if !$userFeatures.share_enabled && !$userFeatures.sharelink_enabled}
            <p style="color: red;">HiDrive Shares sind in ihrem Tarif nicht verfügbar.</p>   
        {:else}
            {#each $dirData.members as item}
                <ListItem itemdata={item} disabled={isDisabled(item)} on:click={handleListItemClick}/>
            {/each}
        {/if}
    </list-content>
</list-layout>