import Filepicker from "./svelte/Filepicker.svelte";
import Display from "./svelte/Display.svelte";
let div = document.createElement("DIV");
let script = document.currentScript;

const currentPath = window.location.pathname;
const endpoint = currentPath.split("/").slice(-2);
const isHiDrivePath = endpoint.includes("hdfp");

if (isHiDrivePath) {
    script.parentNode.insertBefore(div, script);
    new Filepicker({
        target: div,
        props: {}
    })
} else {
    script.parentNode.insertBefore(div, script);
    new Display({
        target: div,
        props: {}
    })
}
