import { Writable, writable } from "svelte/store";
import type { FileData, UserFeatures } from "./custom_types";

// unauthorized, authorized, choosingOptions, done
export const status: Writable<string> = writable("unauthorized");

export const homeURL: Writable<string> = writable();

export const homeDir: Writable<string> = writable();

export const accessToken: Writable<string> = writable();

export const dirData = writable({data: {dirs: 0, files: 0, name: ""}, members: [{}]});

export const currentDirectory: Writable<string> = writable();

export const fileInfo: Writable<FileData> = writable();

// url, link, thumbnail
export const displayChoice: Writable<string> = writable();

export const shareLink: Writable<string> = writable();

export const thumbnailSource: Writable<string> = writable();

export const options = writable({
    original: {"password": "", "maxcount": "", "ttl": ""},
    new: {"password": "", "maxcount": "", "ttl": ""}
});

export const userFeatures: Writable<UserFeatures> = writable();