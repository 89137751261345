<script>
    import { createEventDispatcher } from "svelte";
    import utils from "../../javascript/utils";

    export let itemdata = {
        name: "default.txt",
        type: "file",
        size: "200",
        ctime: "09.05.23 08:20",
        rshare: false,
    };
    export let disabled = false;

    const dispatch = createEventDispatcher();
    function dispatchClick(e) {
        dispatch("click", {event: e, itemdata: itemdata});
    }
</script>

<style>
    list-item {
        --borderStyle: var(--tableRowBorder, 1px dotted #bbb);
        --shareIconColor: var(--iconDefaultColor, #999);
        --selectOrange: var(--stratoOrange, #f80);
        --hoverBGColor: var(--backgroundHoverColor, #eee);

        /*font awesome variables*/
        --fa-plusIcon: "\f067";
        --fa-chainIcon: "\f0c1";

        display: block;
        height: 60px;
        float: none;
        box-sizing: border-box;
        margin: 0;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        width: auto;
        border-bottom: var(--borderStyle);
    }
    list-item.file:hover {
        background-color: var(--hoverBGColor);
    }
    list-item.dir:hover {
        cursor: pointer;
        background-color: var(--hoverBGColor);
    }
    list-item:hover .category-icon {
        filter: invert(70%) sepia(45%) saturate(6862%) hue-rotate(2deg) brightness(104%) contrast(102%);
    }
    .category-icon {
        height: 40px;
        width: 40px;
        filter: invert(65%) sepia(1%) saturate(0%) hue-rotate(10deg) brightness(90%) contrast(91%);
    }
    .itemcontent {
        display: flex;
        flex-direction: row;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
    }
    .select-icon {
        left: 0;
        line-height: 24px;
        margin: 18px 15px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 24px;
        z-index: 1;
        cursor: pointer;
    }
    .dir .select-icon i::before,
    .file .select-icon i::before {
        content: var(--fa-chainIcon);
        color: var(--shareIconColor);
    }
    .dir .select-icon:hover i::before,
    .file .select-icon:hover i::before {
        content: var(--fa-plusIcon);
        color: var(--selectOrange);
    }
    .file-item-icon {
        display: block;
        height: 40px;
        margin: 10px 10px 0 59px;
        width: 40px;
    }
    .file-item-label {
        display: block;
        line-height: 60px;
        overflow: hidden;
        padding: 0 10px;
        text-overflow: ellipsis;
    }
    .file-item-displayname {
        flex: 1;
    }
    .file-item-share-icon{
        min-width: 12px;
        padding: 22px 5px;
    }
    .share-icon {
        color: var(--shareIconColor);
    }
    .file-item-category {
        width: 50px;
    }
    .file-item-size {
        width: 75px;
    }
    .file-item-date {
        width: 125px;
    }
</style>

<list-item
        class="{itemdata.type}"
        on:click={dispatchClick}
        on:keypress={(e) => {if (e.key == "Enter") dispatchClick(e)}}
>   {#if !disabled}
        <div class="select-icon">
            <i class="fa"/>
        </div>
    {/if}
    <div class="itemcontent">
        <span class="file-item-icon">
            {#if itemdata.type == "dir"}
                <img src="src/img/dir.svg" alt="directory-icon" class="category-icon"/>
            {:else if itemdata.type == "file"}
                <img src="src/img/file.svg" alt="file-icon" class="category-icon"/>
            {/if}
        </span>
        <span class="file-item-label file-item-displayname">
            <span class="label-text">{itemdata.name}</span>
        </span>
        <span class="file-item-share-icon">
            {#if itemdata.rshare}
                <i alt="share" class="share-icon fa fa-share-alt"/>
            {/if}
        </span>
        <span class="file-item-label file-item-category">
            <span class="label-text">{itemdata.type}</span>
        </span>
        <span class="file-item-label file-item-size">
            <span class="label-text">{utils.sizeToString(itemdata.size)}</span>
        </span>
        <span class="file-item-label file-item-date">
            <span class="label-text">{utils.convertTimestamp(itemdata.ctime)}</span>
        </span>
    </div>
</list-item>